import CountDown from '../components/countdown';
import Swiper, { Autoplay } from 'swiper';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  init() {
    // JavaScript to be fired on all pages
    const slider = new Swiper('.o-slider', {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 10,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      },
    });
    console.log(slider);
  
    const countdown = document.querySelector('.m-countdown__component');
    const date = countdown.getAttribute('data-date');

    const format = (t) => {
      return t < 10 ? '0' + t : t;
    };

    const getDate = (date) => {
      return new Date(date);
    }

    const complete = () => {

    };

    const render = (time) => {
      const days = document.querySelector('.m-countdown__item--days');
      days.innerHTML = format(time.days);
      const hours = document.querySelector('.m-countdown__item--hours');
      hours.innerHTML = format(time.hours);
      const minutes = document.querySelector('.m-countdown__item--minutes');
      minutes.innerHTML = format(time.minutes);
      const seconds = document.querySelector('.m-countdown__item--seconds');
      seconds.innerHTML = format(time.seconds);
    }

    // eslint-disable-next-line
    const countdownTimer = new CountDown(
      getDate(date),
      render,
      complete
    );

    const media = window.matchMedia('screen and (max-width: 600px)');
    ScrollTrigger.addEventListener('refreshInit', checkSTState);
    gsap.registerPlugin(ScrollTrigger);
    gsap.defaults({overwrite: 'auto'});

    const ST = ScrollTrigger.create({
      trigger: '.m-agenda__events',
      start: 'top 141px',
      end: 'top 283px',
      endTrigger: '.m-relatori',
      pin: '.m-agenda__day',
      scrub: true,
      pinSpacing: false,
      markers: false,
    });

    function checkSTState() {
      if(media.matches) {
        ST.disable();
      } else {
        ST.enable();
      }
    }
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
