// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'
import '@cmyee/pushy/js/pushy.min';

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

// add the imported icons to the library
library.add(faLinkedin);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
